import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import './widget.css';
import '../common.css';
import { SERVER_URL } from '../constants';
import { SettingsType } from '../types/settings.type';
import { themeMui, themeMuiDark, themeStyledComponents, themeStyledComponentsDark } from '../theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ReviewType } from '../types/review.type';
import { EditableTitle } from '../components/editable-title/editable-title.component';
import { LayoutType } from '../types/layout-type.enum';
import { pinSortNewest } from '../utils/pinSort';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { QuestionLink } from '../components/question-link/question-link.component';

const ReviewsCarouselComponent = lazy(() => import('../components/reviews-carousel/reviews-carousel.component'));
const ReviewsMasonryGridComponent = lazy(() => import('../components/reviews-masonry-grid/reviews-masonry-grid.component'));
const ReviewsWallComponent = lazy(() => import('../components/reviews-wall/reviews-wall.component'));
const VideoLayout = lazy(() => import('../components/video-layout/video-layout.component'));
const ReviewsSummaryUser = lazy(() => import('../components/reviews-summary/reviews-summary.component'));

const root = document.querySelector('[src="https://widget.hybrid-reach.com/reviews.js"]');
// const root = document.querySelector('[src="http://127.0.0.1:8080/reviews.js"]');
const container = document.createElement('div');
container.style.width = '100%';

document.addEventListener('DOMContentLoaded', function () {
    if (root.parentElement.tagName === 'HEAD') {
        document.body.appendChild(container);
    }
});

if (root.parentElement.tagName !== 'HEAD') {
    root.parentElement.insertBefore(container, root);
}

type ReviewsResponseType = {
    companyName: string;
    reviews: ReviewType[];
    googleCompanyId: string;
    yelpAlias: string;
    settings: SettingsType;
    googleRating?: number;
    googleRatingsTotal?: number;
    yelpRating?: number;
    yelpRatingsTotal?: number;
    facebookAlias?: string;
    facebookRating?: number;
    facebookRatingsTotal?: number;
    trustpilotAlias?: string;
    trustpilotRating?: number;
    trustpilotRatingsTotal?: number;
    plan?: string;
};

const WidgetComponent = () => {
    const [data, setData] = useState<ReviewsResponseType>();

    const fetchData = async () => {
        const id = root.getAttribute('data-id') ?? '65cfb1c0b679809a01ba41ba';
        if (id) {
            const response = await fetch(`${SERVER_URL}/reviews/widget?id=${id}`).then((res) => res.json());
            setData(response as ReviewsResponseType);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const isProPlan = data?.plan === 'pro';

    const items = useMemo(
        () =>
            [...(data?.reviews || [])]
                ?.filter(
                    (el) =>
                        (el.forceState === 1 || (el.rating >= data?.settings?.minRating && el.forceState !== -1)) &&
                        (el.text || el.videoUrl)
                )
                .filter((el) => (el.videoUrl ? isProPlan : true))
                .sort(pinSortNewest)
                .slice(0, isProPlan ? 10000 : 6),
        [data?.reviews]
    );

    return (
        <StyledThemeProvider theme={data?.settings?.isDark ? themeStyledComponentsDark : themeStyledComponents}>
            <ThemeProvider theme={data?.settings?.isDark ? themeMuiDark : themeMui}>
                <div
                    id="widget-root"
                    style={{
                        fontSize: '14px',
                        padding: '35px 0',
                        width: '100%',
                        maxWidth: '100vw',
                        flex: 0,
                        flexGrow: 0,
                        flexShrink: 0,
                        position: 'relative',
                    }}
                    className={data?.settings?.isDark && 'wrapper-dark'}
                >
                    {data?.settings?.showTitle && <EditableTitle value={data?.settings?.title} />}
                    <Suspense fallback={<></>}>
                        {items?.length && (data?.settings?.layout === LayoutType.carousel || !data?.settings?.layout) && (
                            <ReviewsCarouselComponent
                                items={items}
                                autoplay={data?.settings?.autoplay}
                                accentColor={data?.settings?.accentColor}
                                aiHighlight={isProPlan && data?.settings?.aiHighlight}
                                isWidget
                                isProPlan={isProPlan}
                            />
                        )}
                        {items?.length && data?.settings?.layout === LayoutType.masonry && (
                            <ReviewsMasonryGridComponent
                                items={items}
                                accentColor={data?.settings?.accentColor}
                                aiHighlight={isProPlan && data?.settings?.aiHighlight}
                                isWidget
                                isProPlan={isProPlan}
                            />
                        )}
                        {items?.length && data?.settings?.layout === LayoutType.list && (
                            <ReviewsWallComponent
                                items={items}
                                accentColor={data?.settings?.accentColor}
                                aiHighlight={isProPlan && data?.settings?.aiHighlight}
                                isWidget
                                isProPlan={isProPlan}
                            />
                        )}
                        {items?.length && data?.settings?.layout === LayoutType.video && (
                            <VideoLayout
                                items={items}
                                accentColor={data?.settings?.accentColor}
                                aiHighlight={isProPlan && data?.settings?.aiHighlight}
                                isProPlan={isProPlan}
                            />
                        )}
                    </Suspense>
                    <Suspense>
                        {data?.settings?.showSummary && (
                            <ReviewsSummaryUser
                                googleRating={data.googleRating}
                                googleRatingsTotal={data.googleRatingsTotal}
                                googleCompanyId={data.googleCompanyId}
                                yelpRating={data.yelpRating}
                                yelpRatingsTotal={data.yelpRatingsTotal}
                                yelpAlias={data.yelpAlias}
                                facebookRating={data.facebookRating}
                                facebookRatingsTotal={data.facebookRatingsTotal}
                                facebookAlias={data.facebookAlias}
                                trustpilotRating={data.trustpilotRating}
                                trustpilotRatingsTotal={data.trustpilotRatingsTotal}
                                trustpilotAlias={data.trustpilotAlias}
                            />
                        )}
                    </Suspense>
                    <Tooltip
                        title={
                            <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                                Reviews Platform by Hybrid Reach
                            </Typography>
                        }
                    >
                        <QuestionLink />
                    </Tooltip>
                </div>
            </ThemeProvider>
        </StyledThemeProvider>
    );
};

ReactDOM.render(<WidgetComponent />, container);
