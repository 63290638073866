// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-sentence {
    font-weight: 500;
    border-radius: 5px;
    background: #FFF2C5;
    padding: 0px 2px 0px 15px;
    position: relative;
}

.wrapper-dark .main-sentence {
    background: rgba(255, 242, 197, 0.25);
}

.main-sentence svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
}

.MuiButton-outlined.Mui-active {
    border: 1px solid #4A14FF !important;
    position: relative;
}

.MuiButton-outlined::before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 14px;
    pointer-events: none;
    border: 2px solid transparent;
    transition: all 0.1s linear;
}

.MuiButton-outlined.Mui-active::before {
    border: 2px solid rgba(104, 51, 255, 0.20);
}
`, "",{"version":3,"sources":["webpack://./src/common.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,wBAAwB;IACxB,mBAAmB;IACnB,oBAAoB;IACpB,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":["\n.main-sentence {\n    font-weight: 500;\n    border-radius: 5px;\n    background: #FFF2C5;\n    padding: 0px 2px 0px 15px;\n    position: relative;\n}\n\n.wrapper-dark .main-sentence {\n    background: rgba(255, 242, 197, 0.25);\n}\n\n.main-sentence svg {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 16px;\n    height: 16px;\n}\n\n.MuiButton-outlined.Mui-active {\n    border: 1px solid #4A14FF !important;\n    position: relative;\n}\n\n.MuiButton-outlined::before {\n    content: '';\n    position: absolute;\n    width: calc(100% + 2px);\n    height: calc(100% + 2px);\n    border-radius: 14px;\n    pointer-events: none;\n    border: 2px solid transparent;\n    transition: all 0.1s linear;\n}\n\n.MuiButton-outlined.Mui-active::before {\n    border: 2px solid rgba(104, 51, 255, 0.20);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
