import { EditIconWrapper, TitleWrapper, WidgetTitle } from './editable-title.styles';
import { DEFAULT_WIDGET_TITLE } from '../../constants';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import React from 'react';

export const EditableTitle = ({ value, clickable }: { value: string; clickable?: boolean }) => {
    return (
        <TitleWrapper
            $clickable={clickable}
            onClick={() => {
                if (clickable) {
                    const input = document.getElementById('title-input');
                    input?.focus();
                }
            }}
        >
            <WidgetTitle>{value ?? DEFAULT_WIDGET_TITLE}</WidgetTitle>
            <EditIconWrapper>
                <EditIcon />
            </EditIconWrapper>
        </TitleWrapper>
    );
};
