import Typography from '@mui/material/Typography/Typography';
import styled from 'styled-components';

export const WidgetTitle = styled(Typography).attrs({ variant: 'h5', align: 'center', color: 'textPrimary' })`
    font-size: 32px !important;
    text-align: center !important;
    font-weight: 500 !important;
    letter-spacing: -1.28px !important;

    @media (max-width: 768px) {
        font-size: 24px !important;
    }
`;

export const EditIconWrapper = styled.div`
    position: absolute;
    top: -10px;
    right: 6px;
    background: #fff;
    border-radius: 8px;
    transition: opacity 0.25s;
    opacity: 0;
    cursor: pointer;
`;

export const TitleWrapper = styled.div<{ $clickable?: boolean }>`
    width: fit-content;
    margin-bottom: 32px !important;
    margin-left: auto;
    margin-right: auto;
    padding: 4px 8px;
    border-radius: 12px;
    position: relative;
    transition: background-color 0.25s;
    ${({ $clickable }) =>
        $clickable &&
        `&:hover {
    outline: #E8E9F2 solid  1px;
    
    ${EditIconWrapper} {
      opacity: 1;
    }
  }`}
`;
