import React from 'react';
import { createTheme, PaletteOptions, responsiveFontSizes } from '@mui/material/styles';
import StarEmpty from './assets/star-empty.svg';
import StarEmptyDark from './assets/star-empty-dark.svg';
import { ReactComponent as CheckboxUnchecked } from './assets/checkbox-unchecked.svg';
import { ReactComponent as CheckboxChecked } from './assets/checkbox-checked.svg';

const paletteLight: PaletteOptions = {
    primary: {
        main: '#4A14FF',
    },
    secondary: {
        main: '#ffffff',
    },
    info: {
        main: '#000000',
        contrastText: '#000000',
    },
    grey: {
        // @ts-ignore
        main: '#8A8A99', // Define your grey color here
        light: '#E8E9F2',
        secondary: '#CDCED6',
    },
};

const paletteDark: PaletteOptions = {
    mode: 'dark',
    primary: {
        main: '#4A14FF',
    },
    secondary: {
        main: '#ffffff',
    },
    background: {
        paper: '#303134',
    },
    grey: {
        // @ts-ignore
        main: '#8A8A99', // Define your grey color here
        light: '#E8E9F2',
    },
};

const themeCommon = {
    typography: {
        fontFamily: ['Inter', 'Roboto', 'Noto Sans', 'Arial', 'sans-serif'].join(','),
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '999px',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&.MuiButton-sizeMedium': {
                        padding: '8px 16px',
                    },
                    '&.MuiButton-sizeSmall': {
                        padding: '4px 12px',
                        fontSize: '14px',
                    },
                    '&.MuiButton-containedError': {
                        background: 'rgba(204, 80, 41, 0.12)',
                        color: '#CC5029',
                        '& path': {
                            fill: '#CC5029',
                        },
                    },
                    '&.MuiButton-text': {
                        '& .MuiButton-startIcon path, & .MuiButton-endIcon path': {
                            transition: 'fill 0.1s linear',
                        },
                        '&:hover': {
                            // @ts-ignore
                            color: paletteLight.primary.main,
                            background: '#F7F8FA',
                            '& .MuiButton-startIcon path, & .MuiButton-endIcon path': {
                                // @ts-ignore
                                fill: paletteLight.primary.main,
                            },
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#F7F8FA !important',
                        color: '#CDCED6 !important',
                    },
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '& .MuiTouchRipple-root .MuiTouchRipple-ripple': {
                        color: 'rgba(0,0,0,0.4)', // Change this to your desired color
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '16px', // Set border radius for outlined text inputs
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: <CheckboxUnchecked />,
                checkedIcon: <CheckboxChecked />,
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    '&.MuiInputBase-sizeSmall': {
                        borderRadius: '12px', // Border radius for small size inputs
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: 'none',
                    letterSpacing: '-0.28px',
                },
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                background: 'red',
            },
            '&.MuiTableRow-hover': {
                background: 'red',
            },
        },
    },
};
export const themeMui = responsiveFontSizes(
    createTheme({
        palette: paletteLight,
        ...themeCommon,
        components: {
            ...themeCommon.components,
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#8A8A99',
                        '&.Mui-focused': {
                            color: '#8A8A99',
                        },
                        '&.MuiInputLabel-sizeSmall': {
                            fontSize: '14px',
                            top: '1px',
                            '&.Mui-focused': {
                                top: '0px', // Set your desired top value when focused
                            },
                        },
                        '&.MuiInputLabel-sizeMedium': {
                            top: '-3px',
                            '&.Mui-focused,&.MuiInputLabel-shrink': {
                                top: '0px', // Set your desired top value when focused
                            },
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        '&.MuiOutlinedInput-input': {
                            padding: '12.5px 16px',
                        },
                        '&.MuiInputBase-inputSizeSmall': {
                            padding: '10px 16px',
                            '&.MuiInputBase-inputMultiline': {
                                padding: 0,
                            },
                        },
                    },
                    root: {
                        borderRadius: '16px',
                        '&.MuiInputBase-sizeSmall': {
                            borderRadius: '12px',
                            fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E8E9F2',
                            transition: 'border-color 0.1s linear',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            // @ts-ignore
                            borderColor: paletteLight.primary.main, // Set your desired hover color here
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px',
                        },
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: -2,
                            top: -2,
                            width: '100%',
                            height: '100%',
                            borderRadius: '18px',
                            border: '2px solid transparent',
                            transition: 'all 0.1s linear',
                            pointerEvents: 'none',
                        },
                        '&.Mui-focused::before': {
                            border: '2px solid rgba(104, 51, 255, 0.20)',
                            boxSizing: 'content-box',
                        },
                        '&.MuiInputBase-sizeSmall::before': {
                            borderRadius: '14px',
                        },
                        '& legend': {
                            background: 'white',
                            visibility: 'visible',
                        },
                        background: '#fff',
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        background: '#F7F8FA',
                        borderRadius: '12px',
                        '&::after': {
                            display: 'none', // Disable the ::after element
                        },
                        '&::before': {
                            display: 'none', // Disable the ::after element
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        ...themeCommon.components.MuiButton.styleOverrides.root,
                        '&.MuiButton-outlined': {
                            borderColor: '#E8E9F2',
                            color: '#06070D',
                            background: '#fff',
                        },
                        '&.MuiButton-outlinedPrimary:hover': {
                            // @ts-ignore
                            borderColor: paletteLight.primary.main,
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: { background: '#fff' },
                },
            },
        },
    } as any)
);

export const themeMuiDark = responsiveFontSizes(
    createTheme({
        palette: paletteDark,
        ...themeCommon,
        components: {
            ...themeCommon.components,
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '16px',
                        '&.MuiInputBase-sizeSmall': {
                            borderRadius: '12px', // Border radius for small size inputs
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#25273D',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#4A14FF', // Set your desired hover color here
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        background: '#131422',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#73778C',
                        '&.Mui-focused': {
                            color: '#73778C',
                        },
                    },
                },
            },
        },
    } as any)
);

export const themeStyledComponentsDark = {
    colors: {
        primary: '#4A14FF',
        bg: '#0F0F0F',
        cardBackground: '#1A1A1A',
        text: '#fff',
        textSecondary: '#8C8C8C',
        navigationDot: '#45484a',
        navigationDotActive: '#b0b0b0',
        settingsSectionPaper: '#131422',
        settingsSectionSubPaper: '#1B1C2E',
        questionBackground: 'hsla(0,0%,100%,.1)',
        questionBorder: 'hsla(0,0%,100%,.1)',
        questionText: 'rgb(217,217,227)',
        border: '#262626',
        activeBorderColor: '#4A14FF',
        separator: 'rgb(55,65,81)',
        inactiveGrey: '#808080',
        backgroundActive: 'rgba(160, 160, 160, 0.1)',
        controlBackground: '#262745',
        controlActiveBackground: '#262745',
        inactiveControlColor: '#3f406e',
        activeControlColor: 'radial-gradient(ellipse at top, #4a14ff 20%, #9876f5)',
        light: '#0F0F0F',
        iconLight: '#cdced6',
        carouselSwitcherBg: '#262626',
        chevronBackground: 'rgba(255, 255, 255, 0.20);',
        error: '#cc5029',
    },
    starEmpty: StarEmptyDark,
};

export const themeStyledComponents = {
    colors: {
        primary: '#4A14FF',
        bg: '#ffffff',
        cardBackground: '#ffffff',
        text: '#06070D',
        textSecondary: '#70757a',
        navigationDot: '#e1e1e1',
        navigationDotActive: '#8d8d8d',
        settingsSectionPaper: 'rgb(65,65,65)',
        settingsSectionSubPaper: '#fff',
        questionBackground: 'rgb(247,247,248)',
        questionBorder: 'rgb(217,217,227)',
        questionText: 'rgb(86,88,105)',
        border: '#E8E9F2',
        activeBorderColor: '#4A14FF',
        separator: 'rgb(229,231,235)',
        inactiveGrey: '#8A8A99',
        backgroundActive: 'rgba(160, 160, 160, 0.1)',
        controlBackground: '#E8E9F2',
        controlActiveBackground: '#4A14FF',
        inactiveControlColor: '#fff',
        activeControlColor: '#fff',
        light: '#F7F8FA',
        iconLight: '#cdced6',
        carouselSwitcherBg: '#F2F3FA',
        chevronBackground: 'rgba(6, 7, 13, 0.2)',
        error: '#cc5029',
    },
    starEmpty: StarEmpty,
};
