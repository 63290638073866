export const pinSortNewest = (a, b) => {
    if (a.pin && !b.pin) {
        return -1; // a comes first
    }
    if (!a.pin && b.pin) {
        return 1;
    }
    return b.timestamp - a.timestamp;
};

export const pinSortOldest = (a, b) => {
    if (a.pin && !b.pin) {
        return -1; // a comes first
    }
    if (!a.pin && b.pin) {
        return 1;
    }
    return a.timestamp - b.timestamp;
};

export const pinSortPositive = (a, b) => {
    if (a.pin && !b.pin) {
        return -1; // a comes first
    }
    if (!a.pin && b.pin) {
        return 1;
    }
    return b.rating - a.rating;
};

export const pinSortNegative = (a, b) => {
    if (a.pin && !b.pin) {
        return -1; // a comes first
    }
    if (!a.pin && b.pin) {
        return 1;
    }
    return a.rating - b.rating;
};
